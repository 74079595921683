<template>
    <footer class="footer bg-gray-200 text-center lg:text-left">
        <div class="text-gray-700 text-center p-4" style="background-color: rgba(0, 0, 0, 0.2);">
            © {{currentDate.getFullYear()}} Copyright:
            <a class="text-gray-800" href="https://tailwind-elements.com/">Mediana Services</a>
        </div>
    </footer>
</template>

<script>
export default {
    data(){
        return {
            currentDate: new Date,
        }
    }
}
</script>
